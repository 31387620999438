import { DefaultProps } from '../interface';
import ListGroup from 'react-bootstrap/ListGroup';
import { trackHowToBookStep } from './tracking';
import './how-to-book.scss';
import { FunctionComponent } from 'react';

const json = {
  th: [
    'ขั้นตอนการจองรถ',
    {
      step: [
        { title: 'เตรียมตัวก่อนจองรถ', id: 'prepare' },
        { title: 'เลือกสถานที่รับรถ วันและเวลารับ-คืนรถ', id: 'places' },
        { title: 'เลือกรถว่างคันที่ถูกใจ', id: 'selected-car' },
        { title: 'อ่านรายละเอียดรถและทำการจองรถคันที่เลือก', id: 'car-detail' },
        { title: 'ยืนยันการเช่ารถ', id: 'confirm' }
      ],
      detail: [
        {
          เตรียมตัวก่อนจองรถ: [
            'เตรียมข้อมูลที่ต้องใช้สำหรับการจองรถให้พร้อม',
            'ชื่อของคุณ',
            'วันที่และเวลารับรถ',
            'สถานที่รับรถ',
            'เบอร์โทรศัพท์',
            'อีเมล์',
            'ข้อมูลอื่นๆ เช่น เที่ยวบิน, ชื่อโรงแรม, Line ID (ถ้ามี)'
          ],
          id: 'prepare'
        },
        {
          'เลือกสถานที่รับรถ วันและเวลารับ-คืนรถ': [
            <>
              เลือกวันและเวลารับคืนรถ หลังจากนั้นกดที่ปุ่ม <b>ค้นหารถเช่าราคาดีที่สุด</b>
            </>,
            '/assets/pages/help/how-to-book/1_search_screen.png'
          ],
          id: 'places'
        },
        {
          เลือกรถว่างคันที่ถูกใจ: [
            <>
              ระบบจะโชว์รถว่างพร้อมราคา ตามวันเวลาที่ลูกค้าเลือกเข้ามาท่านสามารถเลือกรถที่ถูกใจ กดปุ่ม{' '}
              <b>อ่านรายละเอียดก่อนจอง</b> เพื่อเข้าสู้หน้ารายละเอียดของรถคันนั้น
            </>,
            '/assets/pages/help/how-to-book/2_search_result_screen.png'
          ],
          id: 'selected-car'
        },
        {
          อ่านรายละเอียดรถและทำการจองรถคันที่เลือก: [
            <>
              อ่านรายละเอียดของรถคันนั้น ๆ เช่น ราคารายวัน ราคารวม ค่ารับส่งรถในจุดรับรถที่เลือก เงินมัดจำ ของรถ
              หลังจากนั้นกดปุ่ม <b>เลือกรถคันนี้</b>
            </>,
            '/assets/pages/help/how-to-book/3_car_detail_screen.png',
            'กรอกรายละเอียดข้อมูลเพื่อให้เจ้าหน้าที่ติดต่อกลับไป และกดปุ่ม ยืนยัน เพื่อส่งการจองให้กับทางเจ้าหน้าที่ทางเว็บไซต์',
            '/assets/pages/help/how-to-book/4_driver_info_screen.png'
          ],
          id: 'car-detail'
        },
        {
          ยืนยันการเช่ารถ: [
            <>
              หลังจาก กด ยืนยัน เรียบร้อยแล้ว ท่านจะได้รับหมายเลขการจอง และอีเมลตอบรับจากระบบ
              และรอทางเจ้าหน้าที่จากบริษัทรถเช่าที่ท่านเลือกติดต่อกลับ
              <b>ภายใน 15 นาที (ในเวลาทำการ 8:30-20:30)</b> หากทางเจ้าหน้าที่ได้ติดต่อกลับมา และมีการคอนเฟิร์ม ส่งเอกสาร
              และโอนเงินจองเรียบร้อย ท่านจะได้รับข้อความและ อีเมลยืนยันการจอง ถือว่า การจองของท่านได้เสร็จสมบูรณ์แล้ว
            </>,
            '/assets/pages/help/how-to-book/5_confirm_screen.png'
          ],
          id: 'confirm'
        }
      ]
    }
  ],
  en: [
    'Booking Steps',
    {
      step: [
        { title: 'Prepare: Get Ready Before Booking a Car', id: 'prepare' },
        { title: 'Choose a Location: Select a Pickup and Return Location, Date, and Time', id: 'places' },
        { title: 'Select a Car: Pick a Preferred Available Car', id: 'selected-car' },
        { title: 'Car Details: Read Car Information and Reserve the Selected Car', id: 'car-detail' },
        { title: 'Confirm: Confirm the Car Rental', id: 'confirm' }
      ],
      detail: [
        {
          'Preparation before Booking a Car': [
            'Ensure the necessary information for the car reservation is ready:',
            'Your name',
            'Pickup Date and Time',
            'Pickup Location',
            'Contact Phone Number',
            'Email Address',
            'Additional Information, e.g., Flight Details, Hotel Name, Line ID (if applicable)'
          ],
          id: 'prepare'
        },
        {
          'Select Pickup Location, Date, and Time': [
            <>
              Choose your preferred pickup location, date, and time for pickup and return. Then click the{' '}
              <b>{`"Find Best Rental Car Rates"`}</b> button.
            </>,
            '/assets/pages/help/how-to-book/1_search_screen.png'
          ],
          id: 'places'
        },
        {
          'Choose an Available Car': [
            <>
              The system will display available cars with prices according to the selected date and time. You can choose
              a car that suits your preference. Click the <b>{`"Read Details Before Booking"`}</b> button to see more
              information about that car.
            </>,
            '/assets/pages/help/how-to-book/2_search_result_screen.png'
          ],
          id: 'selected-car'
        },
        {
          'Read Car Details and Make a Reservation': [
            <>
              Read the details of the selected car, such as daily rate, total price, pickup and return location charges,
              and deposit amount. Then, click the <b>{`"Choose This Car"`}</b> button.
            </>,
            '/assets/pages/help/how-to-book/3_car_detail_screen.png',
            'Enter your contact details inside the "Drivers Details" Box for and press: Check and Comfirm, to submit you booking request.',
            '/assets/pages/help/how-to-book/4_driver_info_screen.png'
          ],
          id: 'car-detail'
        },
        {
          'Provide Contact Information': [
            <>
              Fill in your details so that our staff can contact you. Click the <b>{`"Confirm"`}</b> button to submit
              your reservation to our {`website's`} team.
            </>
          ],
          id: 'booking'
        },
        {
          'Confirm Car Rental': [
            <>
              After clicking <b>{`"Confirm"`}</b>, you will receive a booking number and a confirmation email from our
              system. Our customer service team from the selected car rental company will contact you{' '}
              <b>within 15 minutes (during operating hours from 8:30 AM to 10:00 PM)</b> to confirm, send documents, and
              process the booking payment. Once our customer service team confirms, documents are submitted, and the
              payment is completed, you will receive a confirmation message and email, indicating that your booking
              process is complete.
            </>,
            '/assets/pages/help/how-to-book/5_confirm_screen.png'
          ],
          id: 'confirm'
        }
      ]
    }
  ]
};

const HowToBook: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = json[prefix];

  return (
    <div className="how-to-book">
      <h2>{text[0]}</h2>
      <ListGroup variant="flush" className="booking-sequence">
        {text[1].step.map((key: { title: string; id: string }, index: number) => (
          <ListGroup.Item
            as="a"
            key={key.id}
            href={`#${key.id}`}
            className="booking-sequence__step border-0"
            onClick={() => trackHowToBookStep(key.id)}
          >
            <img src={`/assets/pages/help/how-to-book/${index + 1}_icon.png`} alt={key.id} />
            <span>{key.title}</span>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {text[1].detail.map((section: any, index: number) => {
        const title = Object.keys(section)[0];
        const values = section[title];
        return (
          <div className={`booking-${section.id} mb-3`} key={title} id={section.id}>
            <h2>
              <a>{Object.keys(section)[0]}</a>
            </h2>
            <p className="mb-2">{values[0]}</p>
            {index === 0 && (
              <div className="d-flex">
                <ListGroup variant="flush" className={`${section.id}-sequence`}>
                  {values.slice(1, 4).map((text: string) => (
                    <ListGroup.Item as="p" key={text} className={`${section.id}-sequence__step m-0 border-0`}>
                      <img
                        className="mr-2"
                        src="/assets/pages/help/how-to-book/green_check_icon.png"
                        width={13}
                        alt="Check mark"
                      />
                      {text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <ListGroup variant="flush" className={`${section.id}-sequence`}>
                  {values.slice(4).map((text: string) => (
                    <ListGroup.Item as="p" key={text} className={`${section.id}-sequence__step m-0 border-0`}>
                      <img
                        className="mr-2"
                        src="/assets/pages/help/how-to-book/green_check_icon.png"
                        width={13}
                        alt="Check mark"
                      />
                      {text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}
            {index > 0 &&
              values.slice(1).map((text: string) => {
                if (text.includes('.png')) {
                  return <img className="mb-4" src={text} key={text} alt={title} />;
                }
                return <p key={text}>{text}</p>;
              })}
          </div>
        );
      })}
    </div>
  );
};

export default HowToBook;
